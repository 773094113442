import { getFirebaseBackend } from '../../helpers/firebase/authUtils'

export const state = {
  currentUser: sessionStorage.getItem('authUser'),
  key: null,
  id: null,
  name: null,
  level: null,
  client_id: null,
  spk_id: null,
  transcoder_host: null,
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    saveState('auth.currentUser', newValue)
  },

  SET_USER_INFO(state, obj) {
    if (obj) {
      state.key = obj.key
      state.id = obj.uemail
      state.name = obj.uname
      state.level = obj.ulevel
      state.client_id = obj.client_id
      state.spk_id = obj.spk_id
      state.transcoder_host = obj.transcoder_host
    } else {
      state.key = null
      state.id = null
      state.name = null
      state.level = null
      state.client_id = null
      state.spk_id = null
      state.transcoder_host = null
    }
  },
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser
  },

  getUserKey(state) {
    return state.key
  },
  getUserId(state) {
    return state.id
  },
  getUserName(state) {
    return state.name
  },
  getUserLevel(state) {
    return state.level
  },
  getClientId(state) {
    return state.client_id
  },
  getSpkId(state) {
    return state.spk_id
  },
  getTranscoderHost(state) {
    return state.transcoder_host
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch('validate')
  },

  // Logs in the current user.
  logIn({ commit, dispatch, getters }, { email, password } = {}) {
    if (getters.loggedIn) return dispatch('validate')

    return getFirebaseBackend()
      .loginUser(email, password)
      .then((response) => {
        let user = response
        commit('SET_CURRENT_USER', user)
        commit('SET_USER_INFO', user.userInfo)

        return user
      })
  },

  // Logs out the current user.
  logOut({ commit }) {
    // eslint-disable-next-line no-unused-vars
    commit('SET_CURRENT_USER', null)
    commit('SET_USER_INFO', null)
    sessionStorage.removeItem('axis:vuex')

    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unused-vars
      getFirebaseBackend()
        .logout()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(this._handleError(error))
        })
    })
  },

  // register the user
  register({ commit, dispatch, getters }, { email, password } = {}) {
    if (getters.loggedIn) return dispatch('validate')

    return getFirebaseBackend()
      .registerUser(email, password)
      .then((response) => {
        const user = response
        commit('SET_CURRENT_USER', user)
        return user
      })
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit, dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch('validate')
    return getFirebaseBackend()
      .forgetPassword(email)
      .then((response) => {
        const message = response.data
        return message
      })
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  validate({ commit, state }) {
    if (!state.currentUser) return Promise.resolve(null)
    const user = getFirebaseBackend().getAuthenticatedUser()
    commit('SET_CURRENT_USER', user)
    return user
  },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}
