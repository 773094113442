import { instance } from '@/api/firebase/api-service'

/**
 * 유저목록 조회
 * @param payload 검색 데이터
 * @returns
 */
function selectUserList(payload) {
  return instance.get('user/list', {
    params: payload,
  })
}

/**
 * 유저정보 조회
 * @param {*}
 * @returns
 */
function selectUserInfo(id) {
  return instance.get('user', {
    params: {
      key: id,
    },
  })
}

/**
 * 유저정보 등록
 * @param {*}
 * @returns
 */
function insertUserInfo(payload) {
  return instance.post('user', {
    client_id: payload.client_id,
    uemail: payload.uemail,
    upw: payload.upw,
    uname: payload.uname,
    phone: payload.phone,
    noti_kakao: payload.noti_kakao,
    noti_line: payload.noti_line,
    ulevel: payload.ulevel,
    ftpid: payload.ftpid,
    ftppw: payload.ftppw,
    regdate: payload.regdate,
  })
}

/**
 * 유저정보 수정
 * @param {*}
 * @returns
 */
function updateUserInfo(payload) {
  let params = {
    key: payload.key,
    uemail: payload.uemail,
    upw: payload.upw,
    uname: payload.uname,
    phone: payload.phone,
    noti_kakao: payload.noti_kakao,
    noti_line: payload.noti_line,
    noti_line_token: payload.noti_line_token,
    ulevel: payload.ulevel,
    ftpid: payload.ftpid,
    ftppw: payload.ftppw,
    uid: payload.uid,
  }
  //슈퍼관리자가 수정했을 시
  if (payload.client_id) params.client_id = payload.client_id

  return instance.put('user', params)
}

/**
 * 유저정보 삭제
 * @param {*}
 * @returns
 */
function deleteUserInfo(payload) {
  return instance.delete('user', {
    data: payload,
    // headers: {
    //     'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
    // }
  })
}

/**
 * 로그인 유저정보 조회 (api키 by_pass용)
 * @param {*}
 * @returns
 */
function selectSignInUserInfo(payload) {
  return instance.post('auth/signInFirebase', payload)
}

/**
 * 유저 라인 알림 연동데이터 추가 
 * @param payload 검색 데이터
 * @returns
 */
function updateUserLineInfo(payload){
  return instance.put('auth/line', {
      key: payload.key,
      redirect_uri: payload.redirect_uri,
      code: payload.code
  })
}
/**
 * 유저 라인 알림연동 해제 
 * @param payload 검색 데이터
 * @returns
 */
function revokeUserLineInfo(payload){
  return instance.delete('auth/line', {
    data: payload
  })
}

export { selectUserList, selectUserInfo, insertUserInfo, updateUserInfo, deleteUserInfo, selectSignInUserInfo, updateUserLineInfo, revokeUserLineInfo}
