import store from '@/state/store'

export default [
  {
    //정의되어 있지 않은 페이지 404처리
    path: '*',
    component: () => import('../views/pages/error/404'),
  },
  {
    //404에러
    path: '/error/404',
    name: 'error-404',
    component: () => import('../views/pages/error/404'),
  },
  {
    //500에러
    path: '/error/500',
    name: 'error-500',
    component: () => import('../views/pages/error/500'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home',
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
          store.dispatch('auth/logOut')
          sessionStorage.removeItem('axis:vuex')
        } else if (process.env.VUE_APP_DEFAULT_AUTH === 'fakebackend') {
          store.dispatch('authfack/logout')
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) => route.push('/login'))
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
                name: 'home',
              }
            : {
                ...routeFrom,
              },
        )
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot-password',
    component: () => import('../views/pages/account/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home',
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    //루트(대시보드)
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
      beforeResolve(to, from, next) {
        store.getters['auth/getUserLevel'] > 1 ? next() : next({ name: 'file-list' })
      },
    },
    component: () => import('../views/pages/dashboard/index'),
  },
  {
    //유저 목록
    path: '/user/list',
    name: 'user-list',
    meta: {
      authRequired: true,
      accessLevel: 9,
    },
    component: () => import('../views/pages/user/user-list'),
  },

  {
    //유저 등록
    path: '/user/add',
    name: 'user-add',
    meta: {
      authRequired: true,
      accessLevel: 9,
    },
    component: () => import('../views/pages/user/user-add'),
  },
  {
    //유저 수정
    path: '/user/edit',
    name: 'user-edit',
    meta: {
      authRequired: true,
      accessLevel: 1, //로그인 유저 회원정보 수정되도록
    },
    component: () => import('../views/pages/user/user-edit'),
  },
  {
    //프리셋 목록
    path: '/preset/list',
    name: 'preset-list',
    meta: {
      authRequired: true,
      accessLevel: 1,
    },
    component: () => import('../views/pages/preset/preset-list'),
  },
  {
    //프리셋 등록
    path: '/preset/add',
    name: 'preset-add',
    meta: {
      authRequired: true,
      accessLevel: 1,
    },
    component: () => import('../views/pages/preset/preset-add'),
  },
  {
    //프리셋 수정 및 상세
    path: '/preset/edit',
    name: 'preset-edit',
    meta: {
      authRequired: true,
      accessLevel: 1,
    },
    component: () => import('../views/pages/preset/preset-edit'),
  },
  {
    //카테고리 목록
    path: '/category/list',
    name: 'category-list',
    meta: {
      authRequired: true,
      accessLevel: 1,
    },
    component: () => import('../views/pages/category/category-list'),
  },
  {
    //파일 목록
    path: '/file/list',
    name: 'file-list',
    meta: {
      authRequired: true,
      accessLevel: 1,
    },
    component: () => import('../views/pages/file/file-list'),
  },
  {
    //파일 목록
    path: '/file/file-log-list',
    name: 'file-log-list',
    meta: {
      authRequired: true,
      accessLevel: 9,
    },
    component: () => import('../views/pages/file/file-log-list'),
  },
  {
    //플레이어 plus
    path: '/player/player',
    name: 'player',
    meta: {
      authRequired: false,
    },
    component: () => import('../views/pages/player/player'),
  },
  {
    //플레이어 신형 playerHS
    path: '/player/player_new',
    name: 'player-new',
    meta: {
      authRequired: false,
    },
    component: () => import('../views/pages/player/player-new'),
  },
  {
    //파일 상세
    path: '/file/detail',
    name: 'file-detail',
    meta: {
      authRequired: true,
      accessLevel: 1,
    },
    component: () => import('../views/pages/file/file-detail'),
  },
  {
    //파일 업로드
    path: '/file/upload',
    name: 'file-upload',
    meta: {
      authRequired: true,
      accessLevel: 1,
    },
    component: () => import('../views/pages/file/file-upload'),
  },
  {
    //라이브 채널 리스트
    path: '/live/channel/list',
    name: 'channel-list',
    meta: {
      authRequired: true,
      accessLevel: 1,
    },
    component: () => import('../views/pages/live/channel-list'),
  },
  {
    //라이브 채널생성
    path: '/live/channel/add',
    name: 'channel-add',
    meta: {
      authRequired: true,
      accessLevel: 10,
    },
    component: () => import('../views/pages/live/channel-add'),
  },
  {
    //라이브 채널수정
    path: '/live/channel/edit',
    name: 'channel-edit',
    meta: {
      authRequired: true,
      accessLevel: 10,
    },
    component: () => import('../views/pages/live/channel-edit'),
  },
  {
    //라이브 채널정보
    path: '/live/channel/detail',
    name: 'channel-detail',
    meta: {
      authRequired: true,
      accessLevel: 1,
    },
    component: () => import('../views/pages/live/channel-detail'),
  },
  {
    //녹화파일 리스트
    path: '/live/recording/list',
    name: 'recording-list',
    meta: {
      authRequired: true,
      accessLevel: 1,
    },
    component: () => import('../views/pages/live/live-recordinglist'),
  },
  {
    //편성 관리
    path: '/live/calendar',
    name: 'live-calendar',
    meta: {
      authRequired: true,
      accessLevel: 9,
    },
    component: () => import('../views/pages/live/live-calendar'),
  },
  {
    // 채팅 목록 검색
    path: '/live/chat/list',
    name: 'chat-list',
    meta: {
      authRequired: true,
      accessLevel: 9,
    },
    component: () => import('../views/pages/live/chat-list'),
  },
  {
    // 채팅 상세
    path: '/live/chat/detail',
    name: 'chat-detail',
    meta: {
      authRequired: true,
      accessLevel: 9,
    },
    component: () => import('../views/pages/live/chat-detail'),
  },
  // 채팅 추가
  {
    path: '/live/chat/add',
    name: 'chat-add',
    meta: {
      authRequired: true,
      accessLevel: 9,
    },
    component: () => import('../views/pages/live/chat-add'),
  },
  // 채팅 수정
  {
    path: '/live/chat/edit',
    name: 'chat-edit',
    meta: {
      authRequired: true,
      accessLevel: 9,
    },
    component: () => import('../views/pages/live/chat-edit'),
  },

  {
    //관리자 목록
    path: '/manager/admin-list',
    name: 'admin-list',
    meta: {
      authRequired: true,
      accessLevel: 10,
    },
    component: () => import('../views/pages/manager/admin-list'),
  },
  {
    //관리자 추가
    path: '/manager/admin-add',
    name: 'admin-add',
    meta: {
      authRequired: true,
      accessLevel: 10,
    },
    component: () => import('../views/pages/manager/admin-add'),
  },
  {
    //관리자 수정
    path: '/manager/admin-edit',
    name: 'admin-edit',
    meta: {
      authRequired: true,
      accessLevel: 10,
    },
    component: () => import('../views/pages/manager/admin-edit'),
  },
  {
    //관리자 파일 검색
    path: '/manager/admin-edit-file',
    name: 'admin-edit-file',
    meta: {
      authRequired: true,
      accessLevel: 10,
    },
    component: () => import('../views/pages/manager/admin-edit-file'),
  },
  {
    //관리자 라이브 채널 스트림 목록
    path: '/live/channel/admin/list',
    name: 'channel-admin-list',
    meta: {
      authRequired: true,
      accessLevel: 10,
    },
    component: () => import('../views/pages/live/channel-admin-list'),
  },
]
