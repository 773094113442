// import router from '@/router'
import { EventBus } from '@/utils/event-bus'
import axios from 'axios'
import store from '@/state/store'
import { getFirebaseBackend } from '@/helpers/firebase/authUtils'

const instance = axios.create({
  // baseURL: 'https://asia-northeast3-ovp-api-7737c.cloudfunctions.net/api',
  // baseURL: 'http://127.0.0.1:5001/ovp-api-7737c/asia-northeast3/api',
  baseURL: process.env.VUE_APP_API_URL + '/api',
})

instance.defaults.timeout = 10000 //타임아웃 10sec
// instance.defaults.withCredentials = true
instance.defaults.headers.common['Content-Type'] = 'application/json' /*"application/x-www-form-urlencoded"*/
// instance.defaults.headers.common['api_key'] = process.env.VUE_APP_APIKEY //api key 헤더에 셋팅

instance.interceptors.request.use(
  async function (config) {
    EventBus.$emit('eventbus', 'loadingStart') //loading progress start

    //user 관련 sftpgo api 에서만 사용
    const transcoderHost = store.getters['auth/getTranscoderHost']
    if (transcoderHost && typeof transcoderHost != 'undefined') config.headers.transcoderhost = transcoderHost //api key 헤더에 셋팅

    const apiKey = store.getters['auth/getClientId']
    if (apiKey && typeof apiKey != 'undefined') config.headers.api_key = apiKey //api key 헤더에 셋팅
    // console.log(apiKey)

    let token = null
    const unix_time = Math.floor(new Date().getTime())
    try {
      if (unix_time >= store.state['auth'].currentUser.stsTokenManager.expirationTime) {
        token = await getFirebaseBackend().setrefreshToken()
        console.log('refresh token')
      } else {
        token = store.state['auth'].currentUser.stsTokenManager.accessToken
      }
    } catch (error) {
      // console.log(error)
    }

    if (token) config.headers.Authorization = token

    return config
  },
  function (error) {
    // 요청이 잘못되었을 때 에러가 컴포넌트 단으로 오기 전에 어떤 처리를 할 수 있다.
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  function (response) {
    //인증 만료 로그아웃 처리
    if (response?.data?.status === false && response?.data?.message?.code == 'auth/id-token-expired') {
      console.log('EXPIRED')

      store.dispatch('auth/logOut')
    }

    EventBus.$emit('eventbus', 'loadingEnd') //loading progress end
    return response
  },
  function (error) {
    console.log('api-service', error)
    EventBus.$emit('eventbus', 'loadingEnd') //loading progress end
    // 응답이 에러인 경우에 미리 전처리할 수 있다.
    return error //promise catch 사용바람
  },
)

export { instance }
