import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { EncryptStorage } from 'encrypt-storage'

import modules from './modules'

Vue.use(Vuex)

//vuex encrypt
//참고사이트
//https://www.npmjs.com/package/encrypt-storage
const encryptStorage = EncryptStorage(process.env.VUE_APP_STORAGE_KEY, {
  prefix: process.env.VUE_APP_STORAGE_PREFIX,
  storageType: 'sessionStorage',
  stateManagementUse: true,
})

const store = new Vuex.Store({
  modules,

  plugins: [
    createPersistedState({
      //store 데이터 지속 저장 용도(중요 데이터는 store사용 지양), 해당 플러그인이 없을 경우 스토리지는 메모리로 변경됨(페이지 이동시 데이터 삭제됨)
      //쿠키가 아닌 스토리지에 저장하면 xss 공격에 취약하기 때문에 xss방어가 필요
      storage: encryptStorage,
    }),
  ],
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: false, //process.env.NODE_ENV !== 'production',
})

export default store
/*
import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

export default store
*/
