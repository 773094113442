import { initializeApp } from 'firebase/app'
import { selectSignInUserInfo } from '@/api/firebase/user-api'
// Add the Firebase products that you want to use
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  // signInWithCustomToken,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  setPersistence,
  // inMemoryPersistence
  browserSessionPersistence,
  // browserLocalPersistence,
} from 'firebase/auth'
import 'firebase/firestore'
// import store from '@/state/store'
// import router from '../../router'

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      const app = initializeApp(firebaseConfig)
      const auth = getAuth(app)

      onAuthStateChanged(auth, (user) => {
        if (user) {
          sessionStorage.setItem('authUser', JSON.stringify(user))
        } else {
          sessionStorage.removeItem('authUser')
          sessionStorage.removeItem('axis:vuex')
        }
      })
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      createUserWithEmailAndPassword(auth, email, password).then(
        (userCredential) => {
          // eslint-disable-next-line no-redeclare
          var user = userCredential.currentUser

          resolve(user)
        },
        (error) => {
          reject(this._handleError(error))
        },
      )
    })
  }

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      /*
      browserLocalPersistence   : 브라우저 창이 닫히거나 React Native에서 활동이 폐기된 경우에도 상태가 유지
      browserSessionPersistence : 현재의 세션이나 탭에서만 상태가 유지, 창이 닫히면 삭제됨
      inMemoryPersistence : 상태가 메모리에만 저장되며 창이나 활동이 새로고침되면 삭제
      */
      const auth = getAuth()
      setPersistence(auth, browserSessionPersistence)
        .then(() => {
          //아디 비번 확인 위해
          return signInWithEmailAndPassword(auth, email, password).then(
            async (userCredential) => {
              //아디 비번 확인 후 토큰 삭제 (커스텀 토큰 생성하면 중복 되는거 방지)
              // console.log(userCredential)
              const user = userCredential.user
              const response = await selectSignInUserInfo({ email: user.email, uid: user.uid })
              if (response.data.status === true) {
                // const customToken = response.data.data.accessToken

                user.userInfo = response.data.data
                resolve(user)
              } else {
                reject(this._handleError(response.data.error))
              }
            },
            (error) => {
              reject(this._handleError(error))
            },
          )
        })
        .catch((error) => {
          // Handle Errors here.
          // const errorCode = error.code
          // const errorMessage = error.message
          reject(this._handleError(error))
        })
    })
  }

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      sendPasswordResetEmail(auth, email)
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      signOut(auth)
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          reject(this._handleError(error))
        })
    })
  }

  setLoggeedInUser = (user) => {
    sessionStorage.setItem('authUser', JSON.stringify(user))
  }

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!sessionStorage.getItem('authUser')) return null
    return JSON.parse(sessionStorage.getItem('authUser'))
  }

  /**
   * firebase refresh token generate.
   */
  setrefreshToken = () => {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // user.getIdToken(true)
          user
            .getIdToken(true)
            .then(function (idToken) {
              sessionStorage.setItem('authUser', JSON.stringify(user))
              resolve(idToken)
            })
            .catch(function (error) {
              reject(this._handleError(error))
            })
        } else {
          reject(this._handleError('err'))
        }
      })
    })
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    var errorMessage = error.message
    return errorMessage
  }
}

let _fireBaseBackend = null

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config)
  }
  return _fireBaseBackend
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend
}

export { initFirebaseBackend, getFirebaseBackend }
