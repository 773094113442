import { selectSignInUserInfo } from '@/api/firebase/user-api'

export const state = {
  key: null,
  id: null,
  name: null,
  level: null,
  client_id: null,
}

export const getters = {
  getUserKey(state) {
    return state.key
  },
  getUserId(state) {
    return state.id
  },
  getUserName(state) {
    return state.name
  },
  getUserLevel(state) {
    return state.level
  },

  getUserClientId(state) {
    return state.client_id
  },
}

export const mutations = {
  SET_USER_INFO(state, obj) {
    if (obj) {
      state.key = obj.key
      state.id = obj.uemail
      state.name = obj.uname
      state.level = obj.ulevel
      state.client_id = obj.client_id
    } else {
      state.key = null
      state.id = null
      state.name = null
      state.level = null
      state.client_id = null
    }
  },
}
export const actions = {
  /**
   * 내 정보 가져오기
   * @param {*} param0
   * @param {*} id
   * @returns {status ,message}
   */
  async getMyInfo({ commit }, id) {
    var message = ''
    var status = false
    var data = { name: '', level: -1 }

    try {
      const response = await selectSignInUserInfo({ email: id, uid: null })
      console.log(id)
      console.log(response)
      status = response.status
      message = ''
      if (status) {
        commit('SET_USER_INFO', response.data)
        data.uid = response.data.uid
        data.name = response.data.uname
        data.level = response.data.ulevel
        data.client_id = response.data.client_id
      } else {
        commit('SET_USER_INFO', null)
      }
    } catch (error) {
      commit('SET_USER_INFO', null)
      return Promise.reject(error)
    }
    return Promise.resolve({
      status: status,
      message: message,
      data: data,
    })
  },
}
